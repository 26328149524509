import React, { useContext, useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";

// Context
import { PageSetup } from "../components/context/page-context";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Containers
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Components
import { SingleProduct } from "../components/product/single-product";
import { MobileFilters } from "../components/filter/mobile-filters";

// Utils
import kebabCase from "lodash.kebabcase";
import { getMetafield } from "../components/utils/get-metafield";
import { dynamicSort } from "../components/utils/dynamic-sort";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  margin: 50px 0;

  @media (max-width: 960px) {
    margin: 30px 0 75px 0;
  }

  & .banner-container {
    overflow: hidden;
    margin: 0 0 40px 0;

    & .banner {
      max-height: 515px;
      background-color: #f5eadf;

      & .aspect-ratio-image-container {
        max-height: 515px;
      }

      & .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }

      & h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        z-index: 10;

        /* font-size: 48px;
        line-height: 65px; */

        font-size: 38px;
        line-height: 52px;

        text-transform: uppercase;
        text-align: center;

        color: #0b646f;

        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }

  & .breadcrumbs {
    padding: 0 50px;
    margin: 0 0 10px 0;

    & p {
      font-size: 12px;
      line-height: 28px;

      color: #0b646f;

      margin: 0;
    }

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      padding: 0 10px;
      margin: 0;

      & p {
        font-size: 8px;
        line-height: 28px;
      }
    }
  }

  & .products-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 100px;

    padding: 0 50px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
      grid-row-gap: unset;
    }
  }

  & .empty-products-container {
    padding: 50px;

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    & h1 {
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      color: #0b646f;
    }
  }
`;

const Artist = ({ data, pageContext, location }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  const allArtists = Array.from(
    new Set(
      data.allShopifyProduct.nodes
        .map(product => getMetafield(product, "artist_name"))
        .filter(artist => artist !== null)
        .map(artist => artist.trim())
    )
  ).sort();

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageType: `artist`,
      filterArtists: allArtists,
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  const filteredProducts = data.allShopifyProduct.nodes
    .filter(product => {
      if (pageSetup.priceOption !== null && pageSetup.applyFilter === true) {
        if (pageSetup.priceOption.direction === `greater`) {
          if (
            product.priceRangeV2.minVariantPrice.amount >=
            pageSetup.priceOption.price
          ) {
            return product;
          }
        } else {
          if (
            product.priceRangeV2.minVariantPrice.amount <=
            pageSetup.priceOption.price
          ) {
            return product;
          }
        }
      } else {
        return product;
      }
    })
    .filter(product => {
      if (
        pageSetup.artistOption.length >= 1 &&
        pageSetup.applyFilter === true
      ) {
        if (getMetafield(product, "artist_name") !== null) {
          if (
            pageSetup.artistOption.includes(
              getMetafield(product, "artist_name").trim()
            )
          ) {
            return product;
          }
        }
      } else {
        return product;
      }
    });

  const content = filteredProducts
    .sort(dynamicSort(pageSetup.sortOption))
    .map((product, index) => (
      <SingleProduct
        product={product}
        index={index}
        key={`single_product_${index}_${product.id}`}
        isDesktop={isDesktop}
      />
    ));

  return (
    <>
      <PageSeo seoTitle={pageContext.artist} seoText={null} seoImage={null} />

      <Page>
        {!isDesktop && (
          <section className="breadcrumbs">
            <p className="uppercase">
              <Link to={`/`}>HOME</Link> → <Link to={`/shop/`}>SHOP</Link> →{" "}
              <Link to={`/${kebabCase(pageContext.artist)}/`}>
                {pageContext.artist}
              </Link>
            </p>
          </section>
        )}

        <section className="banner-container">
          <div className="banner">
            <AspectRatioImageContainer
              image={null}
              padding={isDesktop ? 35 : 90}
            >
              <h1>{pageContext.artist}</h1>
            </AspectRatioImageContainer>
          </div>
        </section>

        {isDesktop && (
          <section className="breadcrumbs">
            <p className="uppercase">
              <Link to={`/`}>HOME</Link> → <Link to={`/shop/`}>SHOP</Link> →{" "}
              <Link to={`/${kebabCase(pageContext.artist)}/`}>
                {pageContext.artist}
              </Link>
            </p>
          </section>
        )}

        {content.length >= 1 ? (
          <section className="products-container">{content}</section>
        ) : (
          <section className="empty-products-container">
            <h1>No results</h1>
          </section>
        )}

        {!isDesktop && (
          <MobileFilters
            pageSetup={pageSetup}
            setPageSetup={setPageSetup}
            isDesktop={isDesktop}
            location={location}
          />
        )}
      </Page>
    </>
  );
};

export const query = graphql`
  query Artist($artist: String) {
    allShopifyProduct(
      filter: {
        metafields: {
          elemMatch: {
            key: { eq: "artist_name" }
            namespace: { eq: "custom_fields" }
            value: { eq: $artist }
          }
        }
      }
    ) {
      nodes {
        id
        featuredImage {
          src
          width
          height
          altText
          gatsbyImageData(width: 700)
        }
        mobileImage: featuredImage {
          gatsbyImageData(width: 300)
        }
        updatedAt
        title
        handle
        totalInventory
        status
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        variants {
          id
          title
          price
          availableForSale
          shopifyId
          storefrontId
          inventoryQuantity
          compareAtPrice
          inventoryPolicy
          selectedOptions {
            name
            value
          }
        }
        metafields {
          key
          value
        }
      }
    }
  }
`;

export default Artist;
